.content {
	width: 90%;
}

header {
	background-color: white;
}

.main-menu {
	display: none;
}

.logo {
	width: 25%;
	display: block;
	margin: 0 auto;
	float: none;
}

.un-bien .details .det-1, .un-bien .details .det-3 {
	width: 47%;
    margin-right: 5%;
}

.un-bien .details .det-2, .un-bien .details .det-4 {
    width: 47%;
}

.un-bien .plan {
    width: 60%;
}

.un-bien .plan iframe {
	width: 100%;
}

.form .w50 {
	width: 45%;
}

#nf-form-3-cont #nf-field-12-container, #nf-form-3-cont #nf-field-14-container {
    margin-right: 5%;
}

.un-bien .form .w50 {
	width: 47%;
}

footer {
	display: none;
}

.un-bien .form textarea {
    height: 80mm;
} 